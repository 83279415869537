import { useEffect, useState, useContext } from 'react'
import axios from 'axios';
// import Loader from '../Global/Loader';
import Swal from 'sweetalert2'
import AuthContext from '../Context/auth';
import LangContext from '../Context/lang';

const FormMoto = ({ TripId, Product, cat }) => {
    const [error, seterror] = useState([]);
    const { AuthUser, setAuthUser } = useContext(AuthContext);
    const { Lang, infoperssonnelle, dartaksitsim } = useContext(LangContext);



    console.log(error);

    useEffect(() => {
        if (AuthUser?.token) {
            ValidateToken()
        }
        // console.log(Auth.token);


    }, [])


    const ValidateToken = async () => {
        console.log('run');

        setLoading(true)
        try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/checktoken-user`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${AuthUser?.token}`
                },
            });

            console.log(response);
            // if (response['Valid'] == false) {
            //     return navigate('/')
            // }

            if (response['Valid'] == true) {
                // return navigate('/')

                // if (response['Role'] == 'adminDZ') {

                window.location = '/dashboard'

                // }else{
                //   window.location ='/dashboard'
                // }

            } else {

                setLoading(false)


            }





        } catch (error) {
            setLoading(false)

            // return navigate('/')
        }
    }


    const [NometPrenom, setNometPrenom] = useState('');
    const [Prenom, setPrenom] = useState('');

    const [Job, setJob] = useState('')
    const [Société, setSociété] = useState('')
    const [Contrat, setContrat] = useState('')


    const [secteuActivité, setsecteuActivité] = useState('');
    const [ModePayment, setModePayment] = useState('');

    const [Mobile, setMobile] = useState('')

    // const [Lang, setLang] = useState("ar")
    const [Email, setEmail] = useState('');
    const [ID, setID] = useState('');
    const [Password, setPassword] = useState('')
    const [RePassword, setRePassword] = useState('')

    const [DateGo, setDateGo] = useState('')
    const [DateBack, setDateBack] = useState('')

    const [Children, setChildren] = useState(0)
    const [Personnes, setPersonnes] = useState(1)
    const [Bébé, setBébé] = useState(0)
    const [Time, setTime] = useState("23")
    const [Payment_Methode, setPayment_Methode] = useState("1")
    const [NIN, setNIN] = useState('')

    const [Wilaya, setWilaya] = useState('')

    const [Commune, setCommune] = useState('')
    const [Communesdenaissence, setCommunesdenaissence] = useState([])

    useEffect(() => {

        const GetCommune = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilaya}`);


                setCommunesdenaissence(response['communes'])
                setCommune('')

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetCommune();
        }


    }, [Wilaya])





    function GoNext() {

        setShowerrors(true)

        if (errors?.NometPrenom?.[Lang] !== '' || errors?.Prenom?.[Lang] !== '' || errors?.Mobile?.[Lang] !== '' || errors?.secteuActivité?.[Lang] !== '' || errors?.ModePayment?.[Lang] !== ''
            || errors?.Email?.[Lang] !== '' || errors?.Job?.[Lang] !== '' || errors?.id?.[Lang] !== '' || errors?.Contrat?.[Lang] !== '' || errors?.Société?.[Lang] !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
            return;
        }

        // const url = "http://localhost:8080";
        const url = "https://rookatravel.com/api";


        // const SentForm = async () => {
        //     setLoading(true)
        //     try {
        //         const { data: response } = await axios.post(`${url}/reclamations/form-contact`, { "nom": NometPrenom, "email": Email, "id_demande": ID, 'mobile': Mobile, "password": Password , "secteur" : secteuActivité }, {
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //         });

        //         // console.log(response['errorCode']);
        //         // console.log(response);
        //         if (response['error'] == false && response['msg']) {
        //             // console.log(response.data['err']);
        //             setAuthUser({ token: response['token'] })
        //             Swal.fire({
        //                 title: Lang === "fr" ? 'Succès' : 'تم بنجاح',
        //                 type: 'success',
        //                 text: Lang === "fr" ? 'Votre compte a été créé avec succès' : 'تم إنشاء حسابك بنجاح ',
        //                 // timer: 4000,
        //                 showConfirmButton: true,
        //                 allowOutsideClick: false,
        //                 allowEscapeKey: false,
        //                 icon: 'success',

        //             }).then(function (isConfirm) {
        //                 if (isConfirm) {
        //                     window.location = "/dashboard";
        //                 }
        //             })


        //             // .then(function() {
        //             //     window.location = "/";
        //             // });


        //         } else {

        //             if (response['errorList'].constructor == Array) {
        //                 seterror(response['errorList'])
        //             }



        //             Swal.fire({
        //                 title: 'Problème',
        //                 type: 'error',
        //                 text: Lang === "fr" ? "Il y a un problème lors de l'ajout de données." : "هناك مشكل في إنشاء حسابك يرجى المحاولة مرة أخرى",
        //                 timer: 2000,
        //                 showConfirmButton: false,
        //                 icon: 'error',
        //                 didClose: () => window.scrollTo(0, 0)



        //             });
        //         }

        //         setLoading(false)
        //         // localStorage.removeItem('cart')



        //     } catch (error) {
        //         setLoading(false)
        //         // console.error(error.message);
        //     }
        // }
        const SentForm = async () => {
            setLoading(true)
            try {
                const { data: response } = await axios.post(`${url}/reclamations/form-register`, {
                    "nom": NometPrenom, "prenom": Prenom, "mobile": Mobile,
                    "email": Email, "trip_id": TripId, "secteur": secteuActivité, "cat": cat, 'payment_mode': ModePayment,
                    'job': Job, "societe": Société, "contract": Contrat, "salary": ID,
                    "date_go": DateGo, "date_back": DateBack, "adults": Personnes, "children": Children, "bebe": Bébé, 'time': Time,"nin" : NIN,"payment_methode" : Payment_Methode
                }, {
                    // headers: {
                    //   'Content-Type': 'application/x-www-form-urlencoded'
                    // }
                });

                // console.log(response['errorCode']);
                console.log(response);
                if (response['error'] == false) {
                    // console.log(response.data['err']);
                    Swal.fire({
                        title: Lang === "fr" ? 'Succès' : 'تم الإرسال',
                        type: 'success',
                        text: Lang === "fr" ? 'Votre demande a été envoyée avec succès. Nous vous contacterons bientôt' : 'تم إرسال طلبك بنجاح, سيتم التواصل معك قريبا',
                        // timer: 4000,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function (isConfirm) {
                        if (isConfirm) {
                            window.location.reload()
                        }
                    })


                    // .then(function() {
                    //     window.location = "/";
                    // });


                } else {
                    setLoading(false)


                    if (response['errorList'].constructor == Array) {
                        seterror(response['errorList'])
                    }

                    // let matches = document.querySelectorAll(".alert-store");
                    // let position = matches[0].getBoundingClientRect();




                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: Lang === "fr" ? "Il y a un problème lors de l'ajout de données." : "هناك مشكل في إرسال طلبك يرجى المحاولة مرة أخرى",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 390)



                    });
                }

                setLoading(false)
                // localStorage.removeItem('cart')



            } catch (error) {
                setLoading(false)

                Swal.fire({
                    title: 'Problème',
                    type: 'error',
                    text: Lang === "fr" ? "Il y a un problème lors de l'ajout de données." : "هناك مشكل في إرسال طلبك يرجى المحاولة مرة أخرى",
                    timer: 2000,
                    showConfirmButton: false,
                    icon: 'error',
                    didClose: () => window.scrollTo(0, 390)



                });
                // console.error(error.message);
            }
        }

        SentForm();



    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: { "fr": '', "ar": "" }, Réferenceduproduit: { "fr": '', "ar": "" }, Marque: { "fr": '', "ar": "" } })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    const SecteurItems = ["1", "2", "3", "4", "5"];

    function Validate() {

        if (NometPrenom?.toString().trim().length > 300 || NometPrenom?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": 'Veuillez écrire votre nom', "ar": "يرجى كتابة اللقب" } }))
        } else {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": '', "ar": "" } }))
        }

        if (Prenom?.toString().trim().length > 300 || Prenom?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, Prenom: { "fr": 'Veuillez écrire votre prénom', "ar": "يرجى كتابة الإسم" } }))
        } else {
            seterrors((prev) => ({ ...prev, Prenom: { "fr": '', "ar": "" } }))
        }

        if (Mobile?.toString().trim().length !== 10 || Mobile?.toString().trim() == '' || !onlyNumbers(Mobile) || (Mobile?.slice(0, 2) !== "07" && Mobile?.slice(0, 2) !== "05" && Mobile?.slice(0, 2) !== "06")) {
            seterrors((prev) => ({ ...prev, Mobile: { "fr": 'Vous devez entrer un numéro de téléphone valide (Djezzy Ooredoo Mobilis) (10 chiffres) ', "ar": 'يجب إدخال رقم هاتف صالح (جازي أوريدو موبيليس)' } }))
        } else {
            seterrors((prev) => ({ ...prev, Mobile: { "fr": '', "ar": "" } }))
        }
        if (Email?.toString().trim().length > 300 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: { 'fr': 'Vous devez entrer un email valide.', 'ar': 'يجب عليك إدخال بريد إلكتروني صالح' } }))
        } else {
            seterrors((prev) => ({ ...prev, Email: { "fr": '', "ar": "" } }))
        }


        if (Payment_Methode == "1") {
            
            if (Job?.toString().trim().length > 300 || Job?.toString().trim() == '') {
                seterrors((prev) => ({ ...prev, Job: { "fr": 'Veuillez écrire votre nom et prénom', "ar": "يرجى كتابة وظيفتك" } }))
            } else {
                seterrors((prev) => ({ ...prev, Job: { "fr": '', "ar": "" } }))
            }
    
            if (Société?.toString().trim().length > 300 || Société?.toString().trim() == '') {
                seterrors((prev) => ({ ...prev, Société: { "fr": 'Veuillez écrire votre nom et prénom', "ar": "يرجى كتابة إسم الشركة التي تعمل فيها" } }))
            } else {
                seterrors((prev) => ({ ...prev, Société: { "fr": '', "ar": "" } }))
            }
    
            if (ID?.toString().trim().length > 12 || !onlyNumbers(ID) || ID <= 0) {
                seterrors((prev) => ({ ...prev, id: { 'fr': "S'il vous plait, entrez un ID valide", 'ar': 'يرجى إدخال راتب صحيح' } }))
            } else {
                seterrors((prev) => ({ ...prev, id: { "fr": '', "ar": "" } }))
            }
            if (!onlyNumbers(secteuActivité) || !SecteurItems?.includes(secteuActivité) || secteuActivité?.toString()?.trim() == '') {
                seterrors((prev) => ({ ...prev, secteuActivité: { "fr": "Merci de préciser le secteur d'activité", "ar": "برجى تحديد قطاع النشاط" } }))
            } else {
                seterrors((prev) => ({ ...prev, secteuActivité: { "fr": '', "ar": "" } }))
            }
    
            if (!onlyNumbers(Contrat) || Contrat?.toString()?.trim() == '') {
                seterrors((prev) => ({ ...prev, Contrat: { "fr": "Merci de préciser le secteur d'activité", "ar": "يرجى تحديد نوع عقد العمل" } }))
            } else {
                seterrors((prev) => ({ ...prev, Contrat: { "fr": '', "ar": "" } }))
            }
    
            if (!onlyNumbers(ModePayment) || ModePayment?.toString()?.trim() == '') {
                seterrors((prev) => ({ ...prev, ModePayment: { "fr": "Veuillez choisir un moyen de paiement", "ar": "الرجاء اختيار طريقة الدفع" } }))
            } else {
                seterrors((prev) => ({ ...prev, ModePayment: { "fr": '', "ar": "" } }))
            }
        }else{
            seterrors((prev) => ({ ...prev, Job: { "fr": '', "ar": "" } }))
            seterrors((prev) => ({ ...prev, Société: { "fr": '', "ar": "" } }))
            seterrors((prev) => ({ ...prev, id: { "fr": '', "ar": "" } }))
            seterrors((prev) => ({ ...prev, secteuActivité: { "fr": '', "ar": "" } }))
            seterrors((prev) => ({ ...prev, Contrat: { "fr": '', "ar": "" } }))
            seterrors((prev) => ({ ...prev, ModePayment: { "fr": '', "ar": "" } }))
        }



        if (Password?.toString().length > 300 || Password?.toString().length < 6 || Password?.toString() == '') {
            seterrors((prev) => ({ ...prev, Password: { 'fr': 'Mot de passe incorrect (Min 6 lettres ou chiffres)', 'ar': 'كلمة السر ليست قوية - 8 كلمات على الأقل' } }))
        } else {
            seterrors((prev) => ({ ...prev, Password: { "fr": '', "ar": "" } }))
        }

        if (RePassword?.toString().length > 300 || RePassword?.toString().length < 6 || RePassword?.toString() == '' || RePassword?.toString() !== Password?.toString()) {
            seterrors((prev) => ({ ...prev, rePassword: { 'fr': 'Les deux mots de passe doivent être identiques', 'ar': 'كلمات السر يجب أن تكون متطابقة ' } }))
        } else {
            seterrors((prev) => ({ ...prev, rePassword: { "fr": '', "ar": "" } }))
        }

        // if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 300) {
        //     seterrors((prev) => ({ ...prev, Wilaya: { "fr": 'Veuillez sélectionner La Wilaya ', "ar": "يرجى تحديد الولاية" } }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Wilaya: { "fr": '', "ar": "" } }))
        // }

        // if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 300) {
        //     seterrors((prev) => ({ ...prev, Commune: { "fr": 'Veuillez sélectionner La Commune', "ar": "يرجى تحديد البلدية" } }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Commune: { "fr": '', "ar": "" } }))
        // }


    }

    useEffect(() => {

        Validate();

    }, [NometPrenom, Prenom, Email, ID, Mobile, Password, RePassword, secteuActivité, ModePayment, Job, Contrat, Société,Payment_Methode])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {
                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
        }
    }, [Showerrors])



    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    const Label = {
        "name": { 'ar': "اللقب", 'fr': "Nom" }, "prenom": { 'ar': "الإسم", 'fr': "Prénom" }, "Mobile": { 'ar': "رقم الهاتف", 'fr': "Mobile" }, "Email": { "ar": "الإيميل", "fr": "Email" }
        , "Password": { "ar": "كلمة السر", "fr": "Mot de passe" }, "Repassword": { "ar": "إعادة كلمة السر", "fr": "Répéter le mot de passe" }
        , 'secteur': { 'fr': 'Secteur d’activité', 'ar': 'نوع القطاع' }, 'mode_payment': { 'fr': 'Mode de paiement', 'ar': 'مكان توطين الراتب الشهري' }
        , "job": { 'ar': "الوظيفة", 'fr': "الوظيفة" }, "Société": { 'ar': "الشركة", 'fr': "Société" }, "Contrat": { 'ar': "نوع عقد العمل", 'fr': "Contrat" },
    }

    // const changeLang = (lng) => {
    //     setLang(lng);
    // }

    const secteuActivitéList = [
        { 'fr': 'Salarié (Public)', 'ar': '(قطاع عمومي) موظف', 'value': "1" },
        { 'fr': 'Salarié (Privé)', 'ar': '(قطاع خاص) موظف', 'value': "2" },
        //  { 'fr': 'Retraité ', 'ar': 'متقاعد', 'value': "5" } ,
        //  { 'fr': 'Commerçant', 'ar': 'تاجر', 'value': "3" } ,
        //  { 'fr': 'Chômeur', 'ar': 'بدون عمل', 'value': "4" } ,

        // { "ar": { "value": "أثاث", 'text': "أثاث" }, "fr": { "value": "Meubles", 'text': "Meubles" } },
        // { "ar": { "value": "أجهزة كهرومنزلية وأثاث", 'text': "أجهزة كهرومنزلية وأثاث" }, "fr": { "value": "Électroménager et Meubles", 'text': "Électroménager et Meubles" } },
        // { "ar": { "value": "أخرى", 'text': "أخرى" }, "fr": { "value": "Autre", 'text': "Autre" } },
    ];

    


    return (
        <div className="create-account Aide-page pb-5 form-store-register" lang={Lang === 'ar' ? "ar" : 'fr'}>
            {/* <a href='login' className="text-center m-auto login-register mt-4 mb-4">
                    J'ai un compte ?
                    </a> */}
            {/* <div className="text-center mt-4 mb-5">
                <img alt="logodiardzair" src="/img/logo-fr.svg" width={186} height="59.911" />
            </div> */}
            {/* <div className="ms-4 lang-section mb-1 text-center">
                <div className="sl-nav">
                    <ul>
                        <li>
                            <b className="lang-menu" style={{ color: 'black' }}>{Lang === "fr" ? 'Francais' : 'العربية'}</b>

                            <i className="fa fa-angle-down" style={{ color: 'black' }} aria-hidden="true" />
                            <div className="triangle" ></div>
                            <ul>
                                <div className='pad-lang'></div>
                                <li onClick={() => changeLang('fr')}><i className="sl-flag flag-fr" ><div id="French" /></i> <span className={Lang === "fr" ? "active lang-text" : 'lang-text'}> {Lang === "fr" ? 'Français' : 'الفرنسية'} </span></li>
                                <li onClick={() => changeLang('ar')}><i className="sl-flag flag-usa"><div id="English" /></i> <span className={Lang === "ar" ? "active lang-text" : 'lang-text'}> {Lang === "ar" ? 'العربية' : 'Arabe'}  </span></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='pb-hadi'></div>
                
            </div> */}

            {/* {Loading &&
                <Loader />
            } */}
            {/* <h6 className="text-center mb-5 mt-4">
    Veuillez remplir le formulaire pour vous inscrire au service (Store)
    </h6> */}
            <div className='container py-3'>
                {error?.length > 0 && error?.map((item, i) => (

                    item.constructor == String ?
                        <div className="alert-store" role="alert">
                            - {item}
                        </div>
                        : ''

                ))
                }

            </div>
            <div className={`container  mt-2 d-flex `} lang={Lang === 'ar' ? "ar" : 'fr'} >
                <div className='form-store m-auto'>

                    <div className="row  g-5"
                        dir={Lang === 'ar' ? 'rtl' : 'ltr'}
                    >

                        <div className="col-lg-12" dir={Lang === 'ar' ? 'rtl' : 'ltr'}>
                            <div className='mb-2'>طريقة الدفع</div>
                            <div className="form-check form-check-inline ms-5" dir={Lang === 'ar' ? 'rtl' : 'ltr'}>
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Payment_Methode == '1'} onChange={(e) => setPayment_Methode(e.target.value)} defaultChecked={true} defaultValue="1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">بالتقسيط</label>
                            </div>
                            <div className="form-check form-check-inline" dir={Lang === 'ar' ? 'rtl' : 'ltr'}>
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Payment_Methode == '2'} onChange={(e) => setPayment_Methode(e.target.value)} defaultValue="2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Cash - الدفع نقدا (الثمن الكلي)</label>
                            </div>
                            
                            {/* <div className='alert-form'>{Showerr && ((Civilite !== "Mme" && Civilite !== "Mle" && Civilite !== "M") ? 'يرجى تحديد الحالة المدنية' : '')}</div> */}

                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.name[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="text" onChange={(e) => setNometPrenom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={NometPrenom} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {Lang == 'ar' ? "  - الكتابة باللغة الفرنسية" : "- Écrire en français"}
                            </span>
                            <div className='err-cart-sum'>{Showerrors && errors.NometPrenom[Lang] !== '' ? errors.NometPrenom[Lang] : ''}</div>
                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.prenom[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="text" onChange={(e) => setPrenom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Prenom} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {Lang == 'ar' ? "  - الكتابة باللغة الفرنسية" : "- Écrire en français"}
                            </span>
                            <div className='err-cart-sum'>{Showerrors && errors.Prenom[Lang] !== '' ? errors.Prenom[Lang] : ''}</div>
                        </div>


                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">{Label?.Mobile[Lang]}</label>
                            <input dir="ltr" type="tel" maxLength={10} onChange={(e) => setMobile(e.target.value.replace(/[^0-9]+/g, ''))} value={Mobile} className="form-control" id="inputAddress2" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.Mobile[Lang] !== '' ? errors.Mobile[Lang] : ''}</div>
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputCity" className="form-label">{Label?.Email[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="email" onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z@.0-9]+/g, ''))} value={Email} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Email[Lang] !== '' ? errors.Email[Lang] : ''}</div>
                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">NIN (رقم الهوية الوطنية)</label>
                            <input dir="ltr" type="tel" maxLength={18} onChange={(e) => setNIN(e.target.value.replace(/[^0-9]+/g, ''))} value={NIN} className="form-control" id="inputAddress2" placeholder="" />
                            {/* <div className='err-cart-sum'>{Showerrors && errors.salary !== '' ? errors.salary : ''}</div> */}
                           

                        </div>

                        {Payment_Methode == 1 ? 
                        <>
                         <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.job[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="text" onChange={(e) => setJob(e.target.value)} value={Job} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {/* { Lang == 'ar' ?  "  - الكتابة باللغة الفرنسية"   : "- Écrire en français"} */}
                            </span>
                            <div className='err-cart-sum'>{Showerrors && errors.Job[Lang] !== '' ? errors.Job[Lang] : ''}</div>
                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Société[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="text" onChange={(e) => setSociété(e.target.value)} value={Société} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {/* { Lang == 'ar' ?  "  - الكتابة باللغة الفرنسية"   : "- Écrire en français"} */}
                            </span>
                            <div className='err-cart-sum'>{Showerrors && errors.Société[Lang] !== '' ? errors.Société[Lang] : ''}</div>
                        </div>




                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">الراتب</label>
                            <input dir="ltr" type="tel" maxLength={12} onChange={(e) => setID(e.target.value.replace(/[^0-9]+/g, ''))} value={ID} className="form-control" id="inputAddress2" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.id[Lang] !== '' ? errors.id[Lang] : ''}</div>
                            {Lang == 'ar' ? " ملاحظة : يمكن إضافة راتب الزوجة " : "- Écrire en français"}

                        </div>

                        <div className="col-lg-6" dir={Lang === 'ar' ? "rtl" : 'ltr'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.secteur?.[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setsecteuActivité(e.target.value)}>
                                <option disabled selected={secteuActivité == "" ? "selected" : ''} value={''} >{Label?.secteur?.[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.secteur?.[Lang]}</label>
                                {secteuActivitéList.length > 0 && secteuActivitéList.map((item, i) => (

                                    <option value={item?.value}>{item?.[Lang]}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>

                        </div>


                        <div className="col-lg-6" dir={Lang === 'ar' ? "rtl" : 'ltr'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.mode_payment?.[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setModePayment(e.target.value)}>
                                <option disabled selected={ModePayment == "" ? "selected" : ''} value={''} >{Label?.mode_payment?.[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.mode_payment?.[Lang]}</label>

                                <option value={'1'}>بريد الجزائر</option>
                                <option value={'2'}>مصرف السلام</option>
                                <option value={'3'}>بنك أخر</option>





                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.ModePayment[Lang] !== '' ? errors.ModePayment[Lang] : ''}</div>

                        </div>

                        <div className="col-lg-6" dir={Lang === 'ar' ? "rtl" : 'ltr'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Contrat?.[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setContrat(e.target.value)}>
                                <option disabled selected={Contrat == "" ? "selected" : ''} value={''} >{Label?.Contrat?.[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.Contrat?.[Lang]}</label>
                                <option value={'1'}>CDI</option>
                                <option value={'2'}>CDD</option>
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Contrat[Lang] !== '' ? errors.Contrat[Lang] : ''}</div>

                        </div>
                        
                        </>
                        
                    :""}


                       

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">تاريخ الذهاب</label>
                            <input dir="ltr" type="date" onChange={(e) => setDateGo(e.target.value)} value={DateGo} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {/* { Lang == 'ar' ?  "  - الكتابة باللغة الفرنسية"   : "- Écrire en français"} */}
                            </span>
                            {/* <div className='err-cart-sum'>{Showerrors && errors.Job[Lang] !== '' ? errors.Job[Lang] : ''}</div> */}
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">تاريخ العودة</label>
                            <input dir="ltr" type="date" onChange={(e) => setDateBack(e.target.value)} value={DateBack} min={DateGo} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                                {/* { Lang == 'ar' ?  "  - الكتابة باللغة الفرنسية"   : "- Écrire en français"} */}
                            </span>
                            {/* <div className='err-cart-sum'>{Showerrors && errors.Job[Lang] !== '' ? errors.Job[Lang] : ''}</div> */}
                        </div>

                        <div className="col-lg-6 col-12 mb-1 mt-5">
                            <label htmlFor="inputPassword4" className="form-label mb-0 pb-0">عدد الأشخاص البالغين</label>
                            <span className='age-form d-flex'>
                                +12 سنة فما فوق
                            </span>
                            <div className='product-quantity'>البالغين :
                                <div className='quantity-btn me-3' dir={Lang === 'ar' ? "ltr" : 'ltr'}>
                                    <button className="qtyminus" onClick={() => Personnes !== 1 ? setPersonnes(Personnes - 1) : ''} aria-hidden="true">−</button>
                                    <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Personnes} />
                                    <button className="qtyplus" onClick={() => Personnes < 10 ? setPersonnes(Personnes + 1) : ''} aria-hidden="true">+</button>
                                </div>
                            </div>
                            {/* <input type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
                        </div>


                        <div className="col-lg-6 col-12 mb-1 mt-5">
                            <label htmlFor="inputPassword4" className="form-label mb-0 pb-0">{(infoperssonnelle['nbrEnfants'][Lang])}</label>
                            <span className='age-form d-flex'>
                                من 2 إلى 12 سنة
                            </span>
                            <div className='product-quantity'>{(infoperssonnelle['1'][Lang])} :
                                <div className='quantity-btn me-3' dir={Lang === 'ar' ? "ltr" : 'ltr'}>
                                    <button className="qtyminus" onClick={() => Children !== 0 ? setChildren(Children - 1) : ''} aria-hidden="true">−</button>
                                    <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Children} />
                                    <button className="qtyplus" onClick={() => Children < 10 ? setChildren(Children + 1) : ''} aria-hidden="true">+</button>
                                </div>
                            </div>
                            {/* <input type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
                        </div>

                        <div className="col-lg-12 mb-1 mt-5">
                            <label htmlFor="inputPassword4" className="form-label mb-0 pb-0">عدد الرضع</label>
                            <span className='age-form d-flex'>
                                أقل من 2 سنتين
                            </span>
                            <div className='product-quantity'>الرضع :
                                <div className='quantity-btn me-3' dir={Lang === 'ar' ? "ltr" : 'ltr'}>
                                    <button className="qtyminus" onClick={() => Bébé !== 0 ? setBébé(Bébé - 1) : ''} aria-hidden="true">−</button>
                                    <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Bébé} />
                                    <button className="qtyplus" onClick={() => Bébé < 10 ? setBébé(Bébé + 1) : ''} aria-hidden="true">+</button>
                                </div>
                            </div>
                            {/* <input type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
                        </div>

                        {Payment_Methode == 1 ? 
                        <>
                        <div className="col-md-6 mb-5 mt-1 m-auto">
                            <label className="form-label justify-content-center">ما هي مدة السداد ؟</label>
                            <div className='remboursement-box' dir={Lang === 'ar' ? 'rtl' : ''}>
                                <div onClick={() => setTime('11')} className={`remb-box ${Time == '11' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                    <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                </svg>
                                    11 {(dartaksitsim['duréeMois'][Lang])}</div>
                                <div onClick={() => setTime('23')} className={`remb-box ${Time == '23' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                    <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                </svg>23 {(dartaksitsim['duréeMois'][Lang])}</div>
                                {/* <div onClick={() => setTime('36')} className={`remb-box ${Time == '36' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>36 {(dartaksitsim['duréeMois'][Lang])}</div> */}

                                {/* 
                                            <div onClick={() => setTime('48')} className={`remb-box ${Time == '48' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>48 {(dartaksitsim['duréeMois'][Lang])}</div>
                                            <div onClick={() => setTime('60')} className={`remb-box ${Time == '60' ? `active gradiant-alsalam` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                                                <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                                            </svg>60 {(dartaksitsim['duréeMois'][Lang])}</div> */}


                            </div>
                        </div>
                        </>
                        :""}




                        {/* <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Password[Lang]}</label>
                            <input dir="ltr" type="password" maxLength={300} onChange={(e) => setPassword(e.target.value)} value={Password} className="form-control" id="inputPassword4" />
                            <div className='err-cart-sum'>{Showerrors && errors.Password[Lang] !== '' ? errors.Password[Lang] : ''}</div>

                        </div> */}

                        {/* <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Repassword[Lang]}</label>
                            <input dir="ltr" type="password" maxLength={300} onChange={(e) => setRePassword(e.target.value)} value={RePassword} className="form-control" id="inputRePassword4" />
                            <div className='err-cart-sum'>{Showerrors && errors.rePassword[Lang] !== '' ? errors.rePassword[Lang] : ''}</div>

                        </div> */}
                        {/* <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.wilaya[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                <option disabled selected={Wilaya == "" ? "selected" : ''} value={''} >{Label?.wilaya[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.wilaya[Lang]}</label>
                                {WilayaList.length > 0 && WilayaList.map((item, i) => (

                                    <option value={item.value}>{item.text}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Wilaya[Lang] !== '' ? errors.Wilaya[Lang] : ''}</div>

                        </div> */}
                        {/* <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.commune[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                <option disabled selected={Commune == "" ? "selected" : ''} value={''}>{Communesdenaissence.length > 0 ? Lang === "fr" ? "Commune" : "البلدية" : Lang === "fr" ? "Veuillez sélectionner La Wilaya" : "يرجى تحديد الولاية لإختيار البلدية"}</option>


                                {Communesdenaissence.length > 0 && Communesdenaissence.map((item, i) => (
                                    <option selected={Commune === item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Commune[Lang] !== '' ? errors.Commune[Lang] : ''}</div>

                        </div> */}




                        {/* <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress" className="form-label">{Label?.secteur[Lang]}</label>
                            <input maxLength={300} type="text" className="form-control" onChange={(e) => setsecteuActivité(e.target.value)} value={secteuActivité} id="inputAddress" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>
                        </div> */}
                        <div className="col-12 mt-5">
                            <div onClick={() => GoNext()} className="store-form-register--btn">
                                {Lang === 'fr' ? "Inscription" : "إرسال الطلب"}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}



export default FormMoto